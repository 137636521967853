import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import BackToTop from "../../common/back-to-top.vue";
import reportService from "./reports-service";
import Utility from "../../../shared/utility";
import html2pdf from "html2pdf.js";
import exportLimit from "../../common/export-limit.vue";
export default {
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      max30Rule: [(v) => (v || "").length <= 30 || "Max Length of 30 character", (v) => !!v || "Field is required"],
      orderNo: "",
      showData: false,
      totalRecords: 0,
      excelName: "",
      LPNHistoryData: [],
      popupLPNHistoryData: [],
      LPNHistoryReportDialog: false,
      dataId: 0,
      export50kData: false,
      exportDialog: false,
      apiObj1: {
        user_id: 0,
        pass: 1,
        ordno: "",
      },
      apiObj2: {
        user_id: 0,
        pass: 2,
        data_id: 0,
        include_good_scans: 0,
      },
      headerLPNHistory: [
        { text: "AuditNo", value: "AuditNo", class: "primary customwhite--text" },
        { text: "Order#", value: "Order#", class: "primary customwhite--text" },
        { text: "LPN", value: "LPN", class: "primary customwhite--text" },
        { text: "Date", value: "Date", class: "primary customwhite--text" },
        { text: "Auditor", value: "Auditor", class: "primary customwhite--text" },
        { text: "LPN Count", value: "LPN Count", class: "primary customwhite--text" },
        { text: "Valid Scans", value: "Valid Scans", class: "primary customwhite--text" },
        { text: "Invalid Scans", value: "Invalid Scans", class: "primary customwhite--text" },
        { text: "Missed", value: "Missed", class: "primary customwhite--text" },
        { text: "Status", value: "Status", class: "primary customwhite--text" },
        { text: "Result", value: "Result", class: "primary customwhite--text" },
      ],
      json_fields: {
        AuditNo: "AuditNo",
        "Order#": "Order#",
        LPN: "LPN",
        Date: "Date",
        Auditor: "Auditor",
        "LPN Count": "LPN Count",
        "Valid Scans": "Valid Scans",
        "Invalid Scans": "Invalid Scans",
        Missed: "Missed",
        Status: "Status",
        Result: "Result",
      },
      headerPopupLPNHistory: [
        { text: "Part#", value: "Part#", class: "primary customwhite--text" },
        { text: "BCN", value: "BCN", class: "primary customwhite--text" },
        { text: "Serial#", value: "Serial#", class: "primary customwhite--text" },
        { text: "B/C Scanned", value: "B/C Scanned", class: "primary customwhite--text" },
        { text: "By", value: "By", class: "primary customwhite--text" },
        { text: "On", value: "On", class: "primary customwhite--text" },
        { text: "Result", value: "Result", class: "primary customwhite--text" },
      ],
      json_fieldspopup: {
        "Part#": "Part#",
        BCN: "BCN",
        "Serial#": "Serial#",
        "B/C Scanned": "B/C Scanned",
        By: "By",
        On: "On",
        Result: "Result",
      },
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.orderNo = "";
      this.showData = false;
      this.totalRecords = 0;
      this.excelName = "";
      this.LPNHistoryData = [];
      this.popupLPNHistoryData = [];
      this.LPNHistoryReportDialog = false;
      this.dataId = 0;
      this.export50kData = false;
      this.exportDialog = false;
      this.$refs.orderForm.resetValidation();
    },
    //Run Report
    async runReport() {
      if (this.$refs.orderForm.validate()) {
        this.apiObj1.ordno = this.orderNo;
        this.apiObj1.pass = 1;
        this.apiObj1.user_id = parseInt(this.userId);
        let showMessage = false;
        let data = await reportService.LPNAuditHistoryReport("post", this.apiObj1, showMessage);
        if (data.message !== "NA") {
          const newArr = data.map((obj) => {
            return {
              ...obj,
              Date: obj.Date !== undefined ? Utility.convertESTToLocal(obj.Date) : "",
            };
          });
          this.LPNHistoryData = newArr;
          this.showData = true;
          this.totalRecords = data.length;
        }
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Invalid Order Number",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.LPNHistoryData = [];
        this.showData = false;
        this.totalRecords = 0;
      }
    },
    //Audit Details
    async auditDetails(item) {
      this.dataId = item.data_id;
      this.apiObj2.include_good_scans = 1;
      this.apiObj2.data_id = this.dataId;
      this.apiObj2.pass = 2;
      this.apiObj2.user_id = parseInt(this.userId);
      let showMessage = false;
      let data = await reportService.LPNAuditHistoryReport("post", this.apiObj2, showMessage);
      if (data.message !== "NA") {
        const newArr = data.map((obj) => {
          return {
            ...obj,
            On: obj.On !== undefined ? Utility.convertESTToLocal(obj.On) : "",
          };
        });
        this.popupLPNHistoryData = newArr;
        this.LPNHistoryReportDialog = true;
      } else {
        this.popupLPNHistoryData = [];
        this.LPNHistoryReportDialog = false;
      }
    },
    //To export the Excel file
    async exportExcel1() {
      if (this.totalRecords <= 15000) {
        this.apiObj1.ordno = this.orderNo;
        this.apiObj1.pass = 1;
        this.apiObj1.user_id = parseInt(this.userId);
        this.excelName =
          "LPN_Audit_History_Report_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
        const response = await this.axios.post("/rt/lpn_audit_history_report", this.apiObj1);
        let responseData = JSON.parse(response.data.body.message);
        const newArr = responseData.map((obj) => {
          return {
            ...obj,
            Date: obj.Date !== undefined ? Utility.convertESTToLocal(obj.Date) : "",
          };
        });
        this.export50kData = false;
        return newArr;
      } else {
        this.exportDialog = true;
        this.finishDownload();
        return true;
      }
    },
    //To export the Excel file
    async exportExcel2() {
      if (this.totalRecords <= 15000) {
        this.apiObj2.include_good_scans = 1;
        this.apiObj2.data_id = this.dataId;
        this.apiObj2.pass = 2;
        this.apiObj2.user_id = parseInt(this.userId);
        this.excelName =
          "LPN_Audit_History_Report_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
        const response = await this.axios.post("/rt/lpn_audit_history_report", this.apiObj2);
        let responseData = JSON.parse(response.data.body.message);
        const newArr = responseData.map((obj) => {
          return {
            ...obj,
            On: obj.On !== undefined ? Utility.convertESTToLocal(obj.On) : "",
          };
        });
        this.export50kData = false;
        return newArr;
      } else {
        this.exportDialog = true;
        this.finishDownload();
        return true;
      }
    },
    //Start the Loader when excel process starts
    startDownload() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //Wnd the Loader when the process is completed
    finishDownload() {
      let LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //Export pdf
    async printDetails() {
      this.startDownload();
      this.apiObj1.ordno = this.orderNo;
      this.apiObj1.pass = 1;
      this.apiObj1.user_id = parseInt(this.userId);
      const response = await this.axios.post("/rt/lpn_audit_history_report", this.apiObj1);
      let responseData = JSON.parse(response.data.body.message);
      const newArr = responseData.map((obj) => {
        return {
          ...obj,
          Date: obj.Date !== undefined ? Utility.convertESTToLocal(obj.Date) : "",
        };
      });
      newArr.forEach((item) => {
        delete item["linecolor"];
        delete item["boldbit"];
        delete item["enable_bit"];
        delete item["columncount"];
        delete item["data_id"];
      });
      this.totalLotDetails = [];
      this.totalLotDetails = this.buildHtmlTable(newArr);
      this.pdfName =
        "LPN_Audit_History_Report_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".pdf";
      html2pdf(this.totalLotDetails, {
        margin: 0.5,
        filename: this.pdfName,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 190, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
      });
      this.finishDownload();
    },
    //Export pdf
    async printDetailsPopup() {
      this.startDownload();
      this.apiObj2.data_id = this.dataId;
      this.apiObj2.pass = 2;
      this.apiObj2.user_id = parseInt(this.userId);
      this.apiObj2.include_good_scans = 1;
      const response = await this.axios.post("/rt/lpn_audit_history_report", this.apiObj2);
      let responseData = JSON.parse(response.data.body.message);
      responseData.forEach((item) => {
        delete item["linecolor"];
        delete item["boldbit"];
        delete item["enable_bit"];
        delete item["columncount"];
      });
      const newArr = responseData.map((obj) => {
        return {
          ...obj,
          On: obj.On !== undefined ? Utility.convertESTToLocal(obj.On) : "",
        };
      });
      this.totalLotDetails = [];
      this.totalLotDetails = this.buildHtmlTable(newArr);
      this.pdfName =
        "LPN_Audit_History_Report_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".pdf";
      html2pdf(this.totalLotDetails, {
        margin: 0.5,
        filename: this.pdfName,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 190, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
      });
      this.finishDownload();
    },
    buildHtmlTable(response) {
      this.tblTag = document.createElement("table");
      this.trTag = document.createElement("tr");
      this.thTag = document.createElement("th");
      this.tdTag = document.createElement("td");
      let table = this.tblTag.cloneNode(false);
      table.setAttribute("cellpadding", "0");
      table.setAttribute("cellspacing", "0");
      table.style.border = "1px solid black";
      table.style.borderRight = "none";
      table.style.borderTop = "none";
      let columns = this.addAllColumnHeaders(response, table);
      columns = columns.filter((column) => column !== "tid");
      for (var i = 0, maxi = response.length; i < maxi; ++i) {
        var tr = this.trTag.cloneNode(false);
        for (var j = 0, maxj = columns.length; j < maxj; ++j) {
          var td = this.tdTag.cloneNode(false);
          td.style.border = "1px solid black";
          td.style.padding = "10px";
          td.style.borderLeft = "none";
          td.style.borderBottom = "none";
          var cellValue = response[i][columns[j]];
          td.appendChild(document.createTextNode(cellValue || ""));
          tr.appendChild(td);
        }
        table.appendChild(tr);
      }
      return table;
    },
    addAllColumnHeaders(response, table) {
      let columnSet = [];
      let tr = this.trTag.cloneNode(false);
      for (var i = 0, l = response.length; i < l; i++) {
        for (var key in response[i]) {
          if (columnSet.indexOf(key) === -1) {
            if (key != "tid") {
              columnSet.push(key);
              var th = this.thTag.cloneNode(false);
              th.style.border = "1px solid black";
              th.setAttribute("padding", "5px");
              th.style.borderLeft = "none";
              th.style.borderBottom = "none";
              th.style.backgroundColor = "#005ba8";
              th.style.color = "white";
              if (key == "Audit#") key = "Audit#";
              if (key == "Order#") key = "Order#";
              if (key == "LPN") key = "LPN";
              if (key == "Date") key = "Date";
              if (key == "Auditor") key = "Auditor";
              if (key == "LPN Count") key = "LPN Count";
              if (key == "Valid Scans") key = "Valid Scans";
              if (key == "Invalid Scans") key = "Invalid Scans";
              if (key == "Missed") key = "Missed";
              if (key == "Status") key = "Status";
              if (key == "Result") key = "Result";
              th.appendChild(document.createTextNode(key));
              tr.appendChild(th);
            }
          }
        }
      }
      table.appendChild(tr);
      return columnSet;
    },
    //Close popup
    closeDialog() {
      this.popupLPNHistoryData = [];
      this.LPNHistoryReportDialog = false;
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
    BackToTop,
    exportLimit,
  },
};
